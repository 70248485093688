import { Injectable } from '@angular/core';
import { MenuItem } from '@common/model/menu-item';
import { Role } from '@common/model/auth/role';

@Injectable({
  providedIn: 'root',
})
export class MenuProviderService {

  private menuAllowed(userRoles: Role[], roles: Role[]) {
    return roles.map(role => userRoles.includes(role)).reduce((r1, r2) => r1 || r2);
  }

  public mainMenu(data: { roles: Role[], enterprise?: boolean; }): MenuItem[] {

    const menu: MenuItem[] = [];

    if (data.roles?.length > 0) {
      menu.push(new MenuItem('New Order', 'print_add', 'orders/new'));
      if (data.enterprise) {
        menu.push(new MenuItem('Import Orders', 'upload_file', 'orders/import'));
      }
      menu.push(new MenuItem('Orders History', 'view_list', 'orders/history'));
      // menu.push(new MenuItem('Image Gallery', 'photo_library', 'gallery')); // FA-1670 (pending)
    }

    return menu;
  }

  public adminMenu(data: { roles: Role[] }): MenuItem[] {

    const menu: MenuItem[] = [];

    if (data.roles?.length > 0 && this.menuAllowed(data.roles, [Role.admin])) {
      menu.push(new MenuItem('Product Types', 'category', 'admin/products/types'));
      menu.push(new MenuItem('Users', 'group', 'admin/users'));
    }

    return menu;
  }

}
